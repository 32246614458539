import { useRef } from 'react'
import useSWR, { useSWRConfig } from 'swr'

export default function useDSWR(key, fn, config = { discardStale: true }) {
  const { cache } = useSWRConfig()

  const discardedStale = useRef(false)

  if (key && config?.discardStale && !discardedStale.current) {
    discardedStale.current = true
    cache.delete(key)
  }

  return useSWR(key, fn, config)
}
