import { StackView, GridView } from '@planning-center/ui-kit'
import { Shimmer } from '.'
import { range } from 'lodash'

export function AwardSkeleton() {
  return (
    <StackView alignment="center" grow spacing={3}>
      <Shimmer height={20} width={20} borderRadius="100%" />
      <StackView alignment="center" spacing={2} width="100%">
        <Shimmer height={3} width="70%" />
      </StackView>
    </StackView>
  )
}

export function ChartSkeleton() {
  const makeWidthPercent = (n) => (((n + 3) % 5) + 5) * 10 + '%'

  return (
    <GridView alignment="end" basis={0} grow rows="repeat(5, 1fr)" spacing={2}>
      {range(5).map((n) => (
        <Shimmer height="100%" key={n} width={makeWidthPercent(n)} />
      ))}
    </GridView>
  )
}

export function TopCommentSkeleton() {
  return (
    <>
      <Shimmer height={8} width={8} borderRadius="100%" />
      <StackView spacing={3} width="100%" alignment="center">
        <StackView spacing={1.5} width="70%">
          <Shimmer />
          <Shimmer />
        </StackView>
        <Shimmer width="50%" />
        <Shimmer width="50%" />
      </StackView>
    </>
  )
}
