import { Button, StackView } from '@planning-center/ui-kit'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGoogleLogin } from 'react-google-login'

import { useAuth } from '../utilities/auth'

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

export default function Login() {
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || '/'

  const onSuccess = async ({ tokenId }) => {
    await login(tokenId)
    navigate(from, { replace: true })
  }
  const onFailure = (response) =>
    console.log('[Login failed] response:', response)

  const { signIn } = useGoogleLogin({
    clientId,
    onSuccess,
    onFailure,
    cookiePolicy: 'single_host_origin',
  })

  return (
    <StackView
      axis="vertical"
      alignment="center"
      distribution="center"
      backgroundColor="primary"
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
      }}
    >
      <Button onClick={signIn} title="Login" theme="primary" />
    </StackView>
  )
}
