import { StackView } from '@planning-center/ui-kit'

export default function Page({ children, ...props }) {
  return (
    <StackView
      background="linear-gradient(25deg, var(--colors-primary-lightest), var(--colors-surface-tertiary))"
      color="foreground"
      width="100%"
      minHeight="100vh"
      overflow="scroll"
      padding={3}
      {...props}
    >
      {children}
    </StackView>
  )
}
