import { GridView } from '@planning-center/ui-kit'

export default function PageContentGrid({ children, ...props }) {
  return (
    <GridView
      autoRows="minmax(370px, auto)"
      columns="repeat(4, 1fr)"
      width="100%"
      spacing={4}
      {...props}
    >
      {children}
    </GridView>
  )
}
