import { useRef } from 'react'
import { Text } from '@planning-center/ui-kit'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

export default function NavItem({ children, to, ...props }) {
  const itemRef = useRef()
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  const backgroundColor = match ? 'var(--colors-primary)' : 'transparent'

  const applyHoverStyles = () => {
    if (match) return
    itemRef.current.style.backgroundColor = 'var(--colors-primary-lightest)'
  }

  const removeHoverStyles = () => {
    if (match) return
    itemRef.current.style.backgroundColor = backgroundColor
  }

  return (
    <Link
      onMouseLeave={removeHoverStyles}
      onMouseEnter={applyHoverStyles}
      ref={itemRef}
      style={{
        alignItems: 'center',
        backgroundColor,
        borderRadius: 10,
        padding: '4px 12px',
        color: match ? 'white' : 'var(--colors-primary)',
        display: 'flex',
        justifyContent: 'center',
        textDecoration: 'none',
      }}
      to={to}
      {...props}
    >
      <Text size={4} color="inherit" weight={700}>
        {children}
      </Text>
    </Link>
  )
}
