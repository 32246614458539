import React from 'react'
import { StackView } from '@planning-center/ui-kit'

export default function PageContent({ children, ...props }) {
  return (
    <StackView
      alignItems="center"
      basis={0}
      grow
      marginHorizontal="auto"
      maxWidth="1800px"
      minWidth="1280px"
      width="100%"
      padding={1}
      {...props}
    >
      {children}
    </StackView>
  )
}
