import 'chartkick/chart.js'

import {
  Avatar,
  Badge,
  Heading,
  Link,
  SegmentedControl,
  StackView,
  Text,
  TileView,
} from '@planning-center/ui-kit'
import { AwardSkeleton, ChartSkeleton, TopCommentSkeleton } from './Skeletons'
import { BlankState, PageContentGrid, Section, Shimmer } from './'
import { useChartConfig, useDSWR } from '../hooks'

import { AvatarPlaceholder } from './Temporary'
import { LineChart } from 'react-chartkick'
import { PersonAward } from './PersonAwards'
import { SLACK_POINTS_URL } from '../constants'
import { TopCommentData } from './GlobalDashboard'
import _ from 'lodash'
import createAxiosInstance from '../utilities/axios'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const axiosInstance = createAxiosInstance({ baseURL: SLACK_POINTS_URL })

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data)

export function PersonShowName() {
  const { personId } = useParams()
  const { data, error } = useDSWR(`/person/${personId}`, fetcher)

  if (!personId) return null
  if (error) return <div>failed to load person name</div>
  if (!data) return <Shimmer width={20} height={3} />

  return (
    <Text size={1} weight={600} paddingBottom="4px" color="foregroundSecondary">
      {data.name}
    </Text>
  )
}

export default function Person() {
  const [scope, setScope] = useState('this month')
  return (
    <PageContentGrid>
      <TopInteractionsSection
        gridArea="1 / 1 / span 1 / span 1"
        scope={scope}
      />
      <PointsChartSection
        gridArea="1 / 2 / span 1 / span 2"
        setScope={setScope}
        scope={scope}
      />
      <TopCommentSection gridArea="1 / 4 / span 1 / span 1" scope={scope} />
      <RecentAwardsSection gridArea="2 / 1 / span 1 / span 4" />
    </PageContentGrid>
  )
}

function TopInteractionsSection({ scope, ...props }) {
  return (
    <Section alignment="start" distribution="start" {...props}>
      <Section.Header>
        <Heading level={2}>Top interactions</Heading>
      </Section.Header>
      <Section.Content spacing={4} minHeight="368px">
        <TopInteractions scope={scope} />
      </Section.Content>
    </Section>
  )
}

function TopInteractions({ scope }) {
  const { personId } = useParams()
  const { data, error } = useDSWR(
    `/person/${personId}/top_interactions?scope=${scope}`,
    fetcher
  )

  if (error) return <div>failed to load</div>
  if (!data)
    return (
      <>
        <TopInteractionSkeleton />
        <TopInteractionSkeleton />
      </>
    )

  if (_.isEmpty(data.given) && _.isEmpty(data.received)) {
    return (
      <BlankState column="1 / -1">
        <Heading level={3}>Grinch or Ghost?</Heading>
        <Text>You haven't given or received any points yet</Text>
      </BlankState>
    )
  }

  return (
    <>
      <TopInteraction
        person={data.given.person}
        points={data.given.points}
        type="Gave"
      />
      <TopInteraction
        person={data.received.person}
        points={data.received.points}
        type="Received"
      />
    </>
  )
}

function TopInteraction({ person, points, type }) {
  if (_.isUndefined(points)) return null

  return (
    <StackView alignment="center" spacing={2}>
      <StackView alignment="center" axis="horizontal" spacing={1}>
        <Text>{type}</Text>
        <Badge color="primary-light" size="xs">
          <Text weight={600}>
            {points || 0} point{points === 1 ? '' : 's'}
          </Text>
        </Badge>
        <Text>
          {_.isUndefined(points) ? 'points' : type === 'Gave' ? 'to' : 'from'}
        </Text>
      </StackView>
      <StackView alignment="center" spacing={1}>
        {person && (
          <>
            {person.avatarUrl ? (
              <Avatar size="xl" source={person.avatarUrl} />
            ) : (
              <AvatarPlaceholder size={8} />
            )}
            <Link
              to={`/${person.slackId}`}
              textAlign="center"
              lineHeight={3}
              weight={600}
              size={4}
            >
              {person.name}
            </Link>
          </>
        )}
      </StackView>
    </StackView>
  )
}

function TopInteractionSkeleton() {
  return (
    <StackView alignment="center" spacing={2}>
      <Shimmer width={15} height={3} />
      <Shimmer width={8} height={8} borderRadius="100%" />
      <Shimmer width={10} />
    </StackView>
  )
}

function PointsChartSection({ setScope, scope, ...props }) {
  return (
    <Section grow shrink alignment="start" distribution="start" {...props}>
      <Section.Header>
        <StackView
          alignment="center"
          axis="horizontal"
          justifyContent="space-between"
        >
          <Heading level={2}>Points</Heading>
          <SegmentedControl
            activeSegment={scope}
            onChange={setScope}
            segments={['last month', 'this month']}
            size="sm"
          />
        </StackView>
      </Section.Header>
      <Section.Content basis={0}>
        <PointsChart scope={scope} />
      </Section.Content>
    </Section>
  )
}

function PointsChart({ scope }) {
  const { personId } = useParams()
  const { data, error } = useDSWR(
    `/person/${personId}/total_points?scope=${scope}`,
    fetcher
  )

  const config = useChartConfig()

  if (error) return <div>failed to load</div>
  if (!data) return <ChartSkeleton />

  if (_.isEmpty(data[0].data) && _.isEmpty(data[1].data)) {
    return (
      <BlankState>
        <Heading level={3}>
          These are not the Points you are looking for
        </Heading>
        <Text>Move along...</Text>
      </BlankState>
    )
  }

  return <LineChart data={data} {...config} />
}

function TopCommentSection({ scope, ...props }) {
  return (
    <Section alignment="start" distribution="start" {...props}>
      <Section.Header>
        <Heading level={2}>Top comment</Heading>
      </Section.Header>
      <Section.Content alignment="center" spacing={3}>
        <TopComment scope={scope} />
      </Section.Content>
    </Section>
  )
}

function TopComment({ scope }) {
  const { personId } = useParams()
  const { data, error } = useDSWR(
    `/person/${personId}/top_message?scope=${scope}`,
    fetcher
  )

  if (error) return <div>failed to load</div>
  if (!data) return <TopCommentSkeleton />

  if (!data.points) {
    return (
      <BlankState>
        <Heading level={3}>Crickets</Heading>
        <Text>It's still early</Text>
      </BlankState>
    )
  }

  return <TopCommentData data={data} />
}

function RecentAwardsSection({ ...props }) {
  const { personId } = useParams()
  const [expandedAward, setExpandedAward] = useState(null)

  return (
    <Section
      alignment="start"
      distribution="start"
      marginBottom={30}
      variant="naked"
      {...props}
    >
      <Section.Header>
        <StackView axis="horizontal" alignItems="center" spacing={1}>
          <Heading level={2}>Recent awards</Heading>
          <Link to={`/${personId}/awards`}>see all</Link>
        </StackView>
      </Section.Header>
      <Section.Content
        alignment="start"
        as={TileView}
        axis="horizontal"
        distribution="space-between"
        minCellWidth={30}
        paddingLeft="43px"
        spacing={5}
        width="100%"
      >
        <RecentAwards
          expandedAward={expandedAward}
          setExpandedAward={setExpandedAward}
        />
      </Section.Content>
    </Section>
  )
}

function RecentAwards({ expandedAward, setExpandedAward }) {
  const { personId } = useParams()
  const { data, error } = useDSWR(`/person/${personId}/recent_awards`, fetcher)

  if (error) return <div>failed to load</div>
  if (!data) return _.range(4).map((n) => <AwardSkeleton key={n} />)

  if (_.isEmpty(data)) {
    return (
      <BlankState column="1 / -1">
        <Heading level={3}>No awards were earned last month</Heading>
        <Link to={`/${personId}/awards`}>View awards from all time</Link>
      </BlankState>
    )
  }

  return data.map((award) => (
    <PersonAward
      award={award}
      key={award.name}
      expandedAward={expandedAward}
      setExpandedAward={setExpandedAward}
    />
  ))
}
