import { StackView } from '@planning-center/ui-kit'

export default function BlankState({ children, ...props }) {
  return (
    <StackView
      alignment="center"
      distribution="center"
      grow
      marginHorizontal="auto"
      spacing={1}
      textAlign="center"
      width="70%"
      {...props}
    >
      {children}
    </StackView>
  )
}
