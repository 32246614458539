import React, { useEffect, useState } from 'react'

import { Box } from '@planning-center/ui-kit'
import { DEFAULT_BADGE_SIZE } from '../constants'
import { Shimmer } from './'

export default function PicoBadge({
  badge,
  dropShadowColor = 'var(--colors-dark-2)',
  onClick = null,
  size = DEFAULT_BADGE_SIZE,
  ...props
}) {
  const [path, setPath] = useState(null)

  useEffect(() => {
    import(`../svg/${badge.id}.svg`)
      .then((module) => {
        setPath(module.default)
      })
      .catch((error) => {
        console.error(`Badge with title: ${badge.id} not found.`)
      })
  }, [badge.id])

  const renderBadge = () => {
    if (!path)
      return (
        <Shimmer
          width={DEFAULT_BADGE_SIZE}
          height={DEFAULT_BADGE_SIZE}
          borderRadius="100%"
          zIndex={2}
        />
      )

    return (
      <Box
        alt={badge.name}
        as="img"
        role={onClick ? 'button' : 'img'}
        cursor={onClick ? 'pointer' : 'default'}
        filter={`drop-shadow(0 1px 1px ${dropShadowColor}) drop-shadow(0 2px 4px ${dropShadowColor})`}
        maxHeight={size}
        maxWidth={size * 1.4}
        onClick={onClick}
        src={path}
        zIndex={2}
        {...props}
      />
    )
  }

  return <>{renderBadge()}</>
}
