export const DEFAULT_BADGE_SIZE = 20

export const BASE_API_URL =
  process.env.REACT_APP_LOCAL_POINTS === 'true'
    ? 'http://localhost:5000'
    : 'https://slack-points.pco.bz'

export const ROTATION = [-3.4, -2.9, -2.3, 2.3, 2.8, 3.3]

export const SLACK_AUTH_URL = `${BASE_API_URL}/api/v1/auth`
export const SLACK_POINTS_URL = `${BASE_API_URL}/api/v1/points`
