import { Box } from '@planning-center/ui-kit'

function AvatarPlaceholder({ size = 4, ...props }) {
  return (
    <Box
      borderRadius="100%"
      height={size}
      width={size}
      elevation={2}
      background="linear-gradient(25deg, var(--colors-red-2), var(--colors-red-1))"
      borderColor="red-2"
      borderWidth={4}
      {...props}
    />
  )
}

function BadgePlaceholder({ size = 20 }) {
  return (
    <AvatarPlaceholder
      size={size}
      background="linear-gradient(25deg, var(--colors-yellow-2) 60%, var(--colors-surface))"
      borderColor="yellow-3"
      borderWidth={4}
    />
  )
}

export { AvatarPlaceholder, BadgePlaceholder }
