export default function Logo({ size = 144, fill = 'var(--colors-primary)' }) {
  return (
    <svg
      width={size}
      height={size * 0.24}
      viewBox="0 0 492 120"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Pico points"
    >
      <path
        d="M221.433 29.2059C227.825 29.2059 233.503 31.8919 238.467 37.2639C243.499 42.5679 246.015 49.2659 246.015 57.3579C246.015 65.4499 243.499 72.2159 238.467 77.6559C233.503 83.0959 227.655 85.8159 220.923 85.8159C214.259 85.8159 208.887 83.1299 204.807 77.7579V101.788C204.807 103.445 203.463 104.788 201.807 104.788H192.507C190.85 104.788 189.507 103.445 189.507 101.788V33.1239C189.507 31.467 190.85 30.1239 192.507 30.1239H201.807C203.463 30.1239 204.807 31.467 204.807 33.1239V36.2439C209.499 31.5519 215.041 29.2059 221.433 29.2059ZM204.603 57.6639C204.603 62.0839 205.827 65.7219 208.275 68.5779C210.791 71.3659 213.817 72.7599 217.353 72.7599C220.889 72.7599 223.983 71.3659 226.635 68.5779C229.287 65.7219 230.613 62.0839 230.613 57.6639C230.613 53.2439 229.321 49.5379 226.737 46.5459C224.153 43.5539 221.059 42.0579 217.455 42.0579C213.851 42.0579 210.791 43.5539 208.275 46.5459C205.827 49.4699 204.603 53.1759 204.603 57.6639Z"
        fill={fill}
      />
      <path
        d="M267.13 57.5619C267.13 62.0499 268.388 65.7219 270.904 68.5779C273.488 71.3659 276.752 72.7599 280.696 72.7599C284.708 72.7599 287.972 71.3659 290.488 68.5779C293.072 65.7219 294.364 62.0499 294.364 57.5619C294.364 53.0739 293.072 49.4019 290.488 46.5459C287.972 43.6899 284.708 42.2619 280.696 42.2619C276.752 42.2619 273.488 43.6899 270.904 46.5459C268.388 49.4019 267.13 53.0739 267.13 57.5619ZM309.766 57.5619C309.766 65.5179 307.012 72.2159 301.504 77.6559C295.996 83.0959 289.06 85.8159 280.696 85.8159C272.4 85.8159 265.498 83.0959 259.99 77.6559C254.482 72.2159 251.728 65.5179 251.728 57.5619C251.728 49.6059 254.482 42.9079 259.99 37.4679C265.498 31.9599 272.4 29.2059 280.696 29.2059C289.06 29.2059 295.996 31.9599 301.504 37.4679C307.012 42.9079 309.766 49.6059 309.766 57.5619Z"
        fill={fill}
      />
      <path
        d="M333.88 81.9999C333.88 83.6567 332.537 84.9999 330.88 84.9999H321.58C319.924 84.9999 318.58 83.6567 318.58 81.9999V33.1239C318.58 31.467 319.924 30.1239 321.58 30.1239H330.88C332.537 30.1239 333.88 31.467 333.88 33.1239V81.9999ZM319.804 23.1879C318.104 21.4199 317.254 19.2779 317.254 16.7619C317.254 14.2459 318.104 12.1379 319.804 10.4379C321.572 8.66989 323.714 7.78589 326.23 7.78589C328.746 7.78589 330.854 8.66989 332.554 10.4379C334.322 12.1379 335.206 14.2459 335.206 16.7619C335.206 19.2779 334.322 21.4199 332.554 23.1879C330.854 24.8879 328.746 25.7379 326.23 25.7379C323.714 25.7379 321.572 24.8879 319.804 23.1879Z"
        fill={fill}
      />
      <path
        d="M361.114 54.3999V81.9999C361.114 83.6567 359.77 84.9999 358.114 84.9999H348.814C347.157 84.9999 345.814 83.6567 345.814 81.9999V32.1239C345.814 31.0193 346.709 30.1239 347.814 30.1239H359.114C360.218 30.1239 361.114 31.0193 361.114 32.1239V36.2439C365.738 31.5519 370.94 29.2059 376.72 29.2059C382.5 29.2059 387.328 31.2459 391.204 35.3259C395.148 39.4059 397.12 44.8459 397.12 51.6459V81.9999C397.12 83.6567 395.776 84.9999 394.12 84.9999H384.82C383.163 84.9999 381.82 83.6567 381.82 81.9999V53.9919C381.82 45.5599 378.726 41.3439 372.538 41.3439C369.478 41.3439 366.792 42.4659 364.48 44.7099C362.236 46.8859 361.114 50.1159 361.114 54.3999Z"
        fill={fill}
      />
      <path
        d="M425.869 41.1399V65.7219C425.869 67.8299 426.413 69.4959 427.501 70.7199C428.589 71.8759 429.881 72.4539 431.377 72.4539C432.719 72.4539 433.963 72.1687 435.11 71.5984C437.066 70.6253 439.789 70.7715 440.817 72.6994L443.235 77.2333C443.88 78.4423 443.634 79.9481 442.559 80.7978C438.327 84.1432 433.886 85.8159 429.235 85.8159C423.999 85.8159 419.545 84.0819 415.873 80.6139C412.269 77.1459 410.467 72.4199 410.467 66.4359V41.1399H406.041C404.937 41.1399 404.041 40.2445 404.041 39.1399V32.1239C404.041 31.0193 404.937 30.1239 406.041 30.1239H410.467V16.7019C410.467 15.045 411.81 13.7019 413.467 13.7019H422.869C424.526 13.7019 425.869 15.045 425.869 16.7019V30.1239H437.129C438.234 30.1239 439.129 31.0193 439.129 32.1239V39.1399C439.129 40.2445 438.234 41.1399 437.129 41.1399H425.869Z"
        fill={fill}
      />
      <path
        d="M491.295 67.9659C491.295 73.3379 489.289 77.6559 485.277 80.9199C481.333 84.1839 476.301 85.8159 470.181 85.8159C466.101 85.8159 461.851 85.1019 457.431 83.6739C453.923 82.4865 450.693 80.8707 447.742 78.8265C446.444 77.9277 446.202 76.1424 447.109 74.8505L450.423 70.1298C451.407 68.7291 453.354 68.447 454.792 69.3763C459.752 72.584 464.984 74.1879 470.487 74.1879C472.459 74.1879 474.023 73.8139 475.179 73.0659C476.335 72.2499 476.913 71.1959 476.913 69.9039C476.913 68.6119 476.097 67.4219 474.465 66.3339C472.901 65.2459 470.589 64.2259 467.529 63.2739C464.537 62.3219 462.225 61.5059 460.593 60.8259C459.029 60.1459 457.227 59.1599 455.187 57.8679C451.039 55.2839 448.965 51.4759 448.965 46.4439C448.965 41.3439 451.005 37.1959 455.085 33.9999C459.165 30.8039 464.435 29.2059 470.895 29.2059C476.577 29.2059 482.259 30.8893 487.941 34.2561C489.284 35.0519 489.688 36.7821 488.925 38.1442L486.168 43.0678C485.306 44.6069 483.315 45.0581 481.77 44.2061C477.941 42.094 474.112 41.0379 470.283 41.0379C465.591 41.0379 463.245 42.3299 463.245 44.9139C463.245 46.3419 463.993 47.4639 465.489 48.2799C466.985 49.0959 469.467 50.0479 472.935 51.1359C476.403 52.2239 478.851 53.0739 480.279 53.6859C481.775 54.2299 483.543 55.1139 485.583 56.3379C489.391 58.6499 491.295 62.5259 491.295 67.9659Z"
        fill={fill}
      />
      <path
        d="M31 52.549C31 45.6184 36.8203 40 44 40V40C51.1797 40 57 45.6184 57 52.549V67.451C57 74.3816 51.1797 80 44 80V80C36.8203 80 31 74.3816 31 67.451V52.549Z"
        fill={fill}
      />
      <path
        d="M109 52.549C109 45.6184 114.82 40 122 40V40C129.18 40 135 45.6184 135 52.549V67.451C135 74.3816 129.18 80 122 80V80C114.82 80 109 74.3816 109 67.451V52.549Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.195 74.4093C70.603 72.0013 74.5071 72.0013 76.9151 74.4093C80.5329 78.0271 86.3986 78.0271 90.0164 74.4093C92.4244 72.0013 96.3285 72.0013 98.7365 74.4093C101.144 76.8172 101.144 80.7213 98.7365 83.1293C90.3027 91.5631 76.6288 91.5631 68.195 83.1293C65.787 80.7213 65.787 76.8172 68.195 74.4093Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.124 25.1229C122.649 17.9085 103.667 13.5593 82.9661 13.5593C61.6855 13.5593 42.2275 18.1551 26.528 25.7299C18.844 29.4373 13.6929 38.6109 13.6929 50.5122V69.4878C13.6929 81.3891 18.844 90.5627 26.528 94.2701C42.2275 101.845 61.6855 106.441 82.9661 106.441C103.667 106.441 122.649 102.091 138.124 94.8771C145.997 91.2072 151.307 81.9038 151.307 69.8307V50.1693C151.307 38.0963 145.997 28.7928 138.124 25.1229ZM143.957 12.8551C158.105 19.4508 165 34.6823 165 50.1693V69.8307C165 85.3177 158.105 100.549 143.957 107.145C126.548 115.261 105.559 120 82.9661 120C59.7444 120 38.2178 114.993 20.5304 106.46C6.67956 99.7768 0 84.7466 0 69.4878V50.5122C0 35.2534 6.67955 20.2232 20.5304 13.5404C38.2178 5.00658 59.7444 0 82.9661 0C105.559 0 126.548 4.73914 143.957 12.8551Z"
        fill={fill}
      />
    </svg>
  )
}
