import { useState } from 'react'

import { Award, PageContentGrid } from './'
import { Badge, StackView, Text } from '@planning-center/ui-kit'

import { AwardSkeleton } from './Skeletons'
import { SLACK_POINTS_URL } from '../constants'
import createAxiosInstance from '../utilities/axios'
import { range } from 'lodash'
import { useDSWR } from '../hooks'
import { useParams } from 'react-router-dom'

const axiosInstance = createAxiosInstance({ baseURL: SLACK_POINTS_URL })

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data)

function AwardsGrid({ children }) {
  return (
    <PageContentGrid
      autoRows="minmax(260px,auto)"
      padding={3}
      paddingBottom={20}
      paddingTop={4}
    >
      {children}
    </PageContentGrid>
  )
}

export default function PersonAwards() {
  const { personId } = useParams()
  const { data, error } = useDSWR(
    `${SLACK_POINTS_URL}/person/${personId}/awards`,
    fetcher
  )

  const [expandedAward, setExpandedAward] = useState(null)

  if (error) return <div>failed to load</div>
  if (!data)
    return (
      <AwardsGrid>
        {range(24).map((n) => (
          <AwardSkeleton key={n} />
        ))}
      </AwardsGrid>
    )

  return (
    <AwardsGrid>
      {data.map((award) => (
        <PersonAward
          award={award}
          key={award.badge.name}
          expandedAward={expandedAward}
          setExpandedAward={setExpandedAward}
        />
      ))}
    </AwardsGrid>
  )
}

export function PersonAward({ award, expandedAward, setExpandedAward }) {
  return (
    <StackView
      alignment="center"
      basis={0}
      grow
      height="max-content"
      paddingVertical={2}
      spacing={2}
    >
      <Award
        award={award}
        expandedAward={expandedAward}
        setExpandedAward={setExpandedAward}
      />
      {award.earnedAmount > 1 && (
        <Badge color="dark-4" size="xs">
          <Text weight={600}>{award.earnedAmount}x</Text>
        </Badge>
      )}
    </StackView>
  )
}
