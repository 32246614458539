import axios from 'axios'

import { BASE_API_URL } from '../constants'

const createAxiosInstance = ({ baseURL } = { baseURL: BASE_API_URL }) => {
  const instance = axios.create({
    baseURL,
    withCredentials: true,
  })
  instance.defaults.headers.post['Content-Type'] = 'application/json'

  return instance
}

export default createAxiosInstance
