import { StackView } from '@planning-center/ui-kit'

function Section({ children, variant = 'card', ...props }) {
  let variantProps

  switch (variant) {
    case 'naked':
      variantProps = {}
      break
    case 'card':
      variantProps = {
        backgroundColor: 'surfaceSecondary',
        border: '1px solid var(--colors-grey-4)',
        boxShadow: '0 4px 8px hsla(207 93% 85% / .3)', // primary-lighter w/ decreased alpha
      }
      break
    default:
      variantProps = {}
  }

  return (
    <StackView
      alignment="center"
      basis="400px"
      borderRadius={24}
      distribution="center"
      minHeight="fit-content"
      {...variantProps}
      {...props}
    >
      {children}
    </StackView>
  )
}

function SectionHeader({ children, ...props }) {
  return (
    <StackView
      borderBottomColor="dark-1"
      borderRadius="inherit"
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      borderBottomWidth={1}
      padding={3}
      width="100%"
      {...props}
    >
      {children}
    </StackView>
  )
}

function SectionContent({ as: As = StackView, children, ...props }) {
  return (
    <As
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      grow
      minHeight="340px"
      padding={3}
      radius="inherit"
      width="100%"
      {...props}
    >
      {children}
    </As>
  )
}

Section.Header = SectionHeader
Section.Content = SectionContent

export default Section
