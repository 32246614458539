import 'chartkick/chart.js'

import {
  Avatar,
  Badge,
  GridView,
  Heading,
  Link,
  SegmentedControl,
  StackView,
  Text,
  TileView,
} from '@planning-center/ui-kit'
import { Award, BlankState, PageContentGrid, Section, Shimmer } from './'
import { AwardSkeleton, ChartSkeleton, TopCommentSkeleton } from './Skeletons'
import { useChartConfig, useDSWR } from '../hooks'
import { useEffect, useRef, useState } from 'react'

import { AvatarPlaceholder } from './Temporary'
import { LineChart } from 'react-chartkick'
import { SLACK_POINTS_URL } from '../constants'
import _ from 'lodash'
import axios from 'axios'
import { useAuth } from '../utilities/auth'

const axiosInstance = axios.create({
  baseURL: SLACK_POINTS_URL,
  withCredentials: true,
})

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data)

export default function GlobalDashboard() {
  const [scope, setScope] = useState('this month')

  return (
    <PageContentGrid>
      <LeaderBoardSection gridArea="1 / 1 / span 2 / span 1" scope={scope} />
      <PointsChartSection
        gridArea="1 / 2 / span 1 / span 2"
        setScope={setScope}
        scope={scope}
      />
      <TopCommentSection gridArea="1 / 4 / span 1 / span 1" scope={scope} />
      <RecentAwardsSection gridArea="2 / 2 / span 1 / span 3" />
    </PageContentGrid>
  )
}

function TopCommentSection({ scope, ...props }) {
  return (
    <Section alignment="start" distribution="start" {...props}>
      <Section.Header>
        <Heading level={2}>Top comment</Heading>
      </Section.Header>
      <Section.Content alignment="center" spacing={3}>
        <TopComment scope={scope} />
      </Section.Content>
    </Section>
  )
}

function TopComment({ scope }) {
  const { data, error } = useDSWR(`/top_message?scope=${scope}`, fetcher)

  if (error) return <div>failed to load</div>
  if (!data) return <TopCommentSkeleton />

  if (!data.points) {
    return (
      <BlankState>
        <Heading level={3}>You can do it!</Heading>
        <Text>Probably</Text>
      </BlankState>
    )
  }

  return <TopCommentData data={data} />
}

export function TopCommentData({ data }) {
  const textRef = useRef()
  const [overflowActive, setOverflowActive] = useState(false)

  useEffect(() => {
    function isOverflowActive(event) {
      return (
        event.offsetHeight < event.scrollHeight ||
        event.offsetWidth < event.scrollWidth
      )
    }

    if (!textRef) return

    if (isOverflowActive(textRef.current)) {
      setOverflowActive(true)
      return
    }

    setOverflowActive(false)
  }, [overflowActive])

  return (
    <>
      <StackView alignment="center" spacing={1}>
        {data.person.avatarUrl ? (
          <Avatar size="xl" source={data.person.avatarUrl} />
        ) : (
          <AvatarPlaceholder size={8} />
        )}
        <Link
          to={`/${data.person.slackId}`}
          textAlign="center"
          lineHeight={3}
          size={4}
          weight={600}
        >
          {data.person.name}
        </Link>
      </StackView>
      <Text
        borderBottom={overflowActive ? '1px solid var(--colors-grey-4)' : null}
        className="scroll-shadows"
        innerRef={textRef}
        lineHeight={3}
        maxHeight={15}
        overflowY="scroll"
        paddingBottom={1}
        paddingHorizontal={2}
        textAlign="center"
        weight={600}
      >
        "{data.message.text}"
      </Text>
      <Badge color="primary-light" size="xs">
        <Link color="white" external to={data.message.url}>
          <Text weight={600} size={6}>
            {data.points} point{data.points === 1 ? '' : 's'}
          </Text>
        </Link>
      </Badge>
    </>
  )
}

function RecentAwardsSection({ ...props }) {
  return (
    <Section
      alignment="start"
      distribution="start"
      marginBottom={30}
      variant="naked"
      {...props}
    >
      <Section.Header>
        <StackView axis="horizontal" alignItems="center" spacing={1}>
          <Heading level={2}>Recent awards</Heading>
          <Link to="/awards">see all</Link>
        </StackView>
      </Section.Header>
      <Section.Content
        alignment="start"
        as={TileView}
        axis="horizontal"
        distribution="space-between"
        minCellWidth={30}
        spacing={5}
        width="100%"
      >
        <RecentAwards />
      </Section.Content>
    </Section>
  )
}

function RecentAwards() {
  const { data, error } = useDSWR('/recent_awards', fetcher)
  const [expandedAward, setExpandedAward] = useState(null)

  if (error) return <div>failed to load</div>
  if (!data) return _.range(3).map((n) => <AwardSkeleton key={n} />)

  if (_.isEmpty(data)) {
    return (
      <BlankState column="1 / -1" width="40%">
        <Heading level={3}>No awards were earned last month</Heading>
        <Text>
          If you're seeing this: God speed. And if you're looking to pass the
          time during the zombie apocalypse or whatever landed you at this blank
          state then feel free to browse the{' '}
          <Link to={`/awards`}> all time awards</Link>.
        </Text>
      </BlankState>
    )
  }

  return data.map((award, i) => (
    <RecentAward
      award={award}
      expandedAward={expandedAward}
      setExpandedAward={setExpandedAward}
      key={i}
    />
  ))
}

function RecentAward({ award, expandedAward, setExpandedAward }) {
  return (
    <StackView
      alignment="center"
      basis={0}
      grow
      height="max-content"
      paddingVertical={2}
      spacing={2}
    >
      <Award
        award={award}
        expandedAward={expandedAward}
        setExpandedAward={setExpandedAward}
      />
    </StackView>
  )
}

function LeaderBoardSection({ scope, ...props }) {
  return (
    <Section
      alignment="start"
      distribution="start"
      height="calc(100vh - 128px)"
      maxHeight={112}
      minHeight="393px"
      {...props}
    >
      <Section.Header>
        <Heading level={2}>Leaderboard</Heading>
      </Section.Header>
      <Section.Content
        basis={0}
        className="scroll-shadows"
        overflow="scroll"
        paddingVertical={2}
        spacing={3}
      >
        <LeaderBoard scope={scope} />
      </Section.Content>
    </Section>
  )
}

function LeaderBoard({ scope }) {
  const { data, error } = useDSWR(`/leaderboard?scope=${scope}`, fetcher)
  const { user } = useAuth()

  if (error) return <div>failed to load</div>
  if (!data) return <LeaderBoardSkeleton />

  if (_.isEmpty(data)) {
    return (
      <BlankState distribution="start" paddingTop={16.5}>
        <Heading level={3}>Nobody has any points yet</Heading>
        <Text>How is that even possible?</Text>
      </BlankState>
    )
  }

  return _.sortBy(data, 'points')
    .reverse()
    .map((person, i) => (
      <GridView
        alignment="center"
        backgroundColor={
          person.slackId === user.slackId ? 'primary-lighter' : null
        }
        borderRadius={8}
        columns="28px auto 4fr 1fr"
        paddingVertical={person.slackId === user.slackId ? 1 : 0}
        key={i}
        spacing={2}
        width="100%"
      >
        <Text textAlign="right" weight={600}>
          {i + 1}
        </Text>
        {person.avatarUrl ? (
          <Avatar source={person.avatarUrl} />
        ) : (
          <AvatarPlaceholder />
        )}
        <Link to={`/${person.slackId}`} truncate weight={600}>
          {person.name}
        </Link>
        <Text>{person.points}</Text>
      </GridView>
    ))
}

function LeaderBoardSkeleton() {
  return _.range(15).map((n) => (
    <GridView
      alignment="center"
      columns="28px auto 4fr 1fr"
      key={n}
      spacing={2}
      width="100%"
    >
      <Shimmer />
      <Shimmer width={4} height={4} borderRadius="100%" />
      <Shimmer />
      <Shimmer width={3} />
    </GridView>
  ))
}

function PointsChartSection({ setScope, scope, ...props }) {
  return (
    <Section grow shrink alignment="start" distribution="start" {...props}>
      <Section.Header>
        <StackView
          alignment="center"
          axis="horizontal"
          justifyContent="space-between"
        >
          <Heading level={2}>Points given</Heading>
          <SegmentedControl
            activeSegment={scope}
            onChange={setScope}
            segments={['last month', 'this month']}
            size="sm"
          />
        </StackView>
      </Section.Header>
      <Section.Content basis={0}>
        <PointsChart scope={scope} />
      </Section.Content>
    </Section>
  )
}

function PointsChart({ scope }) {
  const { data, error } = useDSWR(`/total_points?scope=${scope}`, fetcher)
  const config = useChartConfig()

  if (error) return <div>failed to load</div>
  if (!data) return <ChartSkeleton />

  if (_.isEmpty(data)) {
    return (
      <BlankState>
        <Heading level={3}>
          These are not the Points you are looking for
        </Heading>
        <Text>Move along...</Text>
      </BlankState>
    )
  }

  return <LineChart data={data} {...config} />
}
