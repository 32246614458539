import { createContext, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useSWR from 'swr'

import createAxiosInstance from './axios'
import { SLACK_AUTH_URL } from '../constants'

const PICO_POINTS_USER = 'PICO_POINTS_USER'

export const AuthContext = createContext()

const axiosInstance = createAxiosInstance({ baseURL: SLACK_AUTH_URL })

const fetcher = async (url) => {
  let { data } = await axiosInstance.get(url)
  return data
}

export function AuthProvider(props) {
  const localSavedUser = window.localStorage.getItem(PICO_POINTS_USER)

  const { data, error, mutate } = useSWR('/session', fetcher, {
    fallbackData: JSON.parse(localSavedUser),
  })

  const login = async (idToken) => {
    const { data } = await axiosInstance.post('/google_oauth', { idToken })

    window.localStorage.setItem(PICO_POINTS_USER, JSON.stringify(data))
    mutate({ ...data }, false)
  }

  const logout = async () => {
    window.localStorage.removeItem(PICO_POINTS_USER)
    await axiosInstance.delete('/logout')
    mutate()
  }

  const value = { user: data, error, login, logout }

  return <AuthContext.Provider value={value} {...props} />
}

export function RequireAuth({ children }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}

export function useAuth() {
  return useContext(AuthContext)
}
