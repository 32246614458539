import { useTheme } from 'emotion-theming'

export default function useChartConfig() {
  const { colors } = useTheme()

  const chartColors = [colors['primary'], colors['yellow-6']]

  const scaleOptions = {
    ticks: {
      font: { family: 'nunito', weight: 400, size: 10 },
    },
    grid: {
      display: false,
      borderColor: 'transparent',
    },
  }

  const dataset = {
    borderWidth: 3,
    pointBorderWidth: 0,
    pointHoverBorderColor: colors['grey-0'],
    pointHoverBorderWidth: 3,
    pointHoverRadius: 8,
    pointRadius: 4,
    tension: 0.2,
  }

  const library = {
    plugins: {
      legend: {
        align: 'end',
        labels: {
          boxHeight: 8,
          boxWidth: 8,
          font: { family: 'Nunito', weight: 400 },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        bodyAlign: 'center',
        bodyColor: colors['dark-9'],
        bodyFont: { family: 'Nunito', weight: 600, size: 14 },
        borderColor: colors['grey-5'],
        borderWidth: 1,
        callbacks: {
          title: () => '',
        },
        padding: {
          top: 12,
          right: 12,
          bottom: 8,
          left: 12,
        },
      },
    },
    animation: { duration: 750 },
    scales: {
      x: scaleOptions,
      y: scaleOptions,
    },
  }

  const height = '100%'

  return { colors: chartColors, dataset, height, library }
}
