import { Box } from '@planning-center/ui-kit'

export default function Shimmer({ height = 2, width = '100%', ...props }) {
  return (
    <Box
      backgroundColor="dark-0"
      borderRadius={3}
      className="shimmer"
      height={height}
      overflow="hidden"
      position="relative"
      width={width}
      {...props}
    />
  )
}
