import { Button, StackView } from '@planning-center/ui-kit'
import { Header, Logo, NavItem, Page, PageContent } from './'
import { Link, Outlet } from 'react-router-dom'

import { useAuth } from '../utilities/auth'
import { useGoogleLogout } from 'react-google-login'
import { PersonShowName } from './Person'

export default function Layout() {
  const { user, logout } = useAuth()
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const onLogoutSuccess = () => logout()

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
  })

  return (
    <>
      <Page>
        <PageContent>
          <Header>
            <StackView alignment="center" axis="horizontal" spacing={0.5}>
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Logo />
              </Link>
              <PersonShowName />
            </StackView>
            <StackView alignment="center" axis="horizontal" spacing={2}>
              <NavItem to="/">Home</NavItem>
              <NavItem to={`${user.slackId}`}>My points</NavItem>
              <NavItem to={`${user.slackId}/awards`}>My awards</NavItem>
              <Button
                radius={10}
                onClick={signOut}
                theme="primary"
                title="Logout"
                variant="naked"
              />
            </StackView>
          </Header>
          <Outlet />
        </PageContent>
      </Page>
    </>
  )
}
