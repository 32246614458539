import '@fontsource/nunito'
import './App.css'

import { AuthProvider, RequireAuth } from './utilities/auth'
import {
  Awards,
  GlobalDashboard,
  Layout,
  Login,
  Person,
  PersonAwards,
} from './components'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@planning-center/ui-kit'

const theme = {
  colors: {
    primary: {
      lightest: 'hsla(207 98% 96% / 1)',
      lighter: 'hsla(207 93% 85% / 1)',
      light: 'hsla(207 92% 68% / 1)',
      base: 'hsla(207 91% 42% / 1)',
      dark: 'hsla(207 93% 32% / 1)',
      darker: 'hsla(207 96% 20% / 1)',
      darkest: 'hsla(207 98% 12% / 1)',
    },
  },
}

export default function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route index element={<GlobalDashboard />} />
              <Route path="/awards" element={<Awards />} />
              <Route path=":personId" element={<Person />} />
              <Route path=":personId/awards" element={<PersonAwards />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  )
}
