import { useState } from 'react'
import { Award, PageContentGrid } from '.'

import { AwardSkeleton } from './Skeletons'
import { SLACK_POINTS_URL } from '../constants'
import createAxiosInstance from '../utilities/axios'
import { range } from 'lodash'
import { useDSWR } from '../hooks'

const axiosInstance = createAxiosInstance({ baseURL: SLACK_POINTS_URL })

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data)

function AwardsGrid({ children }) {
  return (
    <PageContentGrid
      autoRows="minmax(260px,auto)"
      padding={3}
      paddingBottom={20}
      paddingTop={4}
    >
      {children}
    </PageContentGrid>
  )
}

export default function Awards() {
  const { data, error } = useDSWR(`/awards`, fetcher)
  const [expandedAward, setExpandedAward] = useState(null)

  if (error) return <div>failed to load</div>
  if (!data)
    return (
      <AwardsGrid>
        {range(24).map((n) => (
          <AwardSkeleton key={n} />
        ))}
      </AwardsGrid>
    )

  return (
    <AwardsGrid>
      {data.map((award) => (
        <Award
          award={award}
          key={award.badge.name}
          expandedAward={expandedAward}
          setExpandedAward={setExpandedAward}
        />
      ))}
    </AwardsGrid>
  )
}
