import React from 'react'
import { StackView } from '@planning-center/ui-kit'

export default function Header({ children, ...props }) {
  return (
    <StackView
      alignment="center"
      as="header"
      axis="horizontal"
      width="100%"
      padding={3}
      paddingBottom={4}
      paddingTop={0}
      distribution="space-between"
      {...props}
    >
      {children}
    </StackView>
  )
}
