import { Heading, Link, StackView, Text } from '@planning-center/ui-kit'

import { DEFAULT_BADGE_SIZE, ROTATION } from '../constants'
import { PicoBadge } from './'
import { useMountTransition } from '../hooks'
import _ from 'lodash'

export default function Award({
  award,
  expandedAward,
  setExpandedAward,
  ...props
}) {
  const showCard = expandedAward === award.badge.name

  const hasTransitionedIn = useMountTransition(showCard, 100)
  const handleClick = () => {
    showCard ? setExpandedAward(null) : setExpandedAward(award.badge.name)
  }

  return (
    <StackView alignment="center" position="relative" {...props}>
      <StackView
        alignment="center"
        distribution="center"
        height={DEFAULT_BADGE_SIZE}
      >
        <PicoBadge
          badge={award.badge}
          onClick={handleClick}
          transition="all cubic-bezier(.55,-0.25,0,1.75) 400ms"
          transform={
            showCard
              ? `scale(1.15) rotate(${
                  ROTATION[_.random(ROTATION.length - 1)]
                }deg)`
              : 'scale(1) rotate(0deg)'
          }
          willChange="transform"
          zIndex={showCard ? 5 : 1}
        />
      </StackView>

      <Heading level={3} marginTop="25px" textAlign="center">
        {award.badge.name}
      </Heading>

      {(hasTransitionedIn || showCard) && (
        <AwardCard
          border="1px solid var(--colors-grey-4)"
          opacity={hasTransitionedIn && showCard ? 1 : 0}
          position="absolute"
          top={20}
          transition="all ease-in-out 120ms"
          transitionDelay={showCard ? '100ms' : 0}
          willChange="opacity"
          zIndex={hasTransitionedIn && showCard ? 4 : 0}
        >
          <AwardCardSection>
            <Heading level={3} textAlign="center">
              {award.badge.name}
            </Heading>
            {_.some(award.people) && (
              <>
                <Text
                  size={4}
                  textAlign="center"
                  width="100%"
                  wordWrap="break-word"
                >
                  {award.badge.description}
                </Text>
                <Text size={5} textAlign="center" width="100%">
                  {award.people.map((person, i) => (
                    <Link
                      key={person.name}
                      paddingHorizontal="2px"
                      to={`/${person.slackId}`}
                    >
                      {person.name}
                      {i < award.people.length - 1 && ','}
                    </Link>
                  ))}
                </Text>
              </>
            )}
          </AwardCardSection>
          <AwardCardSection
            backgroundColor="grey-2"
            borderBottomLeftRadius={24}
            borderBottomRightRadius={24}
            paddingVertical={2}
          >
            <Text color="foregroundSecondary" size={4} textAlign="center">
              {award.badge.requirement}
            </Text>
          </AwardCardSection>
        </AwardCard>
      )}
    </StackView>
  )
}

function AwardCard({ children, ...props }) {
  return (
    <StackView
      backgroundColor="surfaceSecondary"
      borderRadius="24px"
      boxShadow="0 6px 8px hsla(207 93% 85% / .5)" // primary-lighter w/ decreased alpha'
      marginTop={-5}
      minHeight="fit-content"
      paddingTop={5}
      width={41}
      {...props}
    >
      {children}
    </StackView>
  )
}

function AwardCardSection({ children, ...props }) {
  return (
    <StackView padding={3} spacing={2} {...props}>
      {children}
    </StackView>
  )
}
